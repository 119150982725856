@import 'sass/import';

.login {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &__content {
    width: 100%;
    height: fit-content;
    max-width: 450px;
    box-sizing: border-box;
    padding: 24px;
    text-align: center;
    transition: all 0.25s cubic-bezier(0.165, 0.84, 0.44, 1);
    opacity: 1;
    > * {
      margin: 8px;
      width: 100%;
    }
    &__loading {
      opacity: 0.2;
      pointer-events: none;
      scale: 0.8;
      transform: scale(0.9);
    }

    form {
      text-align: center;
      margin: 0px;
      > * {
        width: 100%;
        margin: 8px;
      }
    }
  }


  .loaderContainer {
    position: absolute;
    top: auto;
    left: auto;
  }
}
