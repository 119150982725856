@import 'sass/import';

.deviceDetails {
  width: 100%;

  &__padding {
    margin: 0 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  &__icon {
    width: 24px;
    margin: 18px 0;
  }

  &__horizontal {
    display: flex;
    flex-direction: row;
    align-items: center;
  }


  &__centeredList {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__map {
    height: 300px;
    margin: 24px 0;
  }
}