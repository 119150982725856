@import 'sass/import';

.userDetails {
  width: 100%;
  box-sizing: border-box;
  padding: 16px;


  &__small {
    padding: 4px 0px;
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__displayName {
    flex-grow: 1;
    p {
      margin-block-end: 0;
      margin-block-start: 0;
    }
    h3 {
      margin-block-end: 0;
      margin-block-start: 0;
    }
  }

}