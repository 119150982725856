@import 'sass/import';

.socialButton {
  position: relative;
  font-size: 14px;
  cursor: pointer;
  outline:none;
  font-weight: bold;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0 24px 0 48px;
  min-height: 40px;
  border-radius: 20px;
  border: none;
  color: $color-primary-dark-text;
  transition: all 0.25s cubic-bezier(0.165, 0.84, 0.44, 1);
  letter-spacing: 1px;
  box-shadow: none;

  &:hover {
    box-shadow: 0px 2px 10px -3px rgba(0,0,0,0.60);
  }

  &__full {
    text-align: center;
    width: 100%;
    padding: 0 48px 0 48px;
  }

  &__icon {
    position: absolute;
    left: 0;
    top: 0;
    height: 24px;
    margin: 8px;
  }
}

