@import 'sass/import';

.appLayout {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__content {
    height: 100%;
    overflow: auto;
  }
}