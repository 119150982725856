@import 'sass/import';

.deviceSmallItem {
  display: flex;
  flex-direction: row;
  width: fit-content;
  position: relative;
  cursor: pointer;
  margin: 8px 4px;
  padding: 8px;
  border-radius: 8px;
  transition: all 0.25s cubic-bezier(0.165, 0.84, 0.44, 1);
  box-shadow: 0px 1px 5px -3px rgba(0,0,0,0.60);

  &__withIcon {
    padding-right: 32px;
  }

  &:hover {
    box-shadow: 0px 2px 10px -3px rgba(0,0,0,0.60);
    .deviceListItem__icon {
      opacity: 0.6;
    }
  }

  &__icon {
    position: absolute;
    right: 8px;
    top: 8px;
    width: 16px;
    height: 16px;
    opacity: 0.3;
  }
}