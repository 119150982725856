@import 'sass/import';

.usersResearch {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__users {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    overflow: auto;
    padding: 24px 16px 16px 16px;
    width: 100%;
  }
  
  &__user {
    box-sizing: border-box;
    cursor: pointer;
    width: 100%;
    margin: 8px;
    padding: 0 12px;
    border-radius: 8px;
    transition: all 0.25s cubic-bezier(0.165, 0.84, 0.44, 1);
    box-shadow: 0px 1px 5px -3px rgba(0,0,0,0.60);

    &__selected {
      pointer-events: none;
      box-shadow: none;
      background-color: $color-selection-background;
      &:hover {
        box-shadow: none !important;
      }
    }

    &:hover {
      box-shadow: 0px 2px 10px -3px rgba(0,0,0,0.60);
    }
  }

  &__detail {
    padding: 5px;
    &__data {
      margin-top: 10px;
      margin-bottom: 10px;
      span {
        font-weight: bold;
      }
    }
  }

}