@import 'sass/import';

.input {
  width: 100%;
  border: none;
  border-bottom: 1px solid $color-divider;
  background: none;
  height: auto;
  padding: 8px 0;

  color: $color-primary-text;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.4;
  font-family: 'Montserrat', sans-serif;

  &:focus {
    outline-width: 0;
    border-bottom: 1px solid $color-primary;
  }

  &__area {
    height: 100%;
  }
}