.transferOwnershipModal {
  display: flex;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-top: 16px;
  flex-direction: column;
  align-items: center;
  > * {
    margin: 8px 0;
  }

  &__actions {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;
    > * {
      margin-left: 8px;
    }
  }
}