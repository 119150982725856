@import 'sass/import';

.inputSearch {
  display: flex;
  padding: 12px;
  box-shadow: 0px 1px 5px -3px rgba(0,0,0,0.60);
  input {
    flex-grow: 1;
    margin-right: 12px;
    border: none;
    border-bottom: 1px solid $color-divider;
    background: none;
    padding: 8px 0;

    color: $color-primary-text;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.4;
    font-family: 'Montserrat', sans-serif;

    &:focus {
      outline-width: 0;
      border-bottom: 1px solid $color-primary;
    }
  }
}