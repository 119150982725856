@import 'sass/import';

html,
body,
#root {
  height: 100%; /* needed for proper layout */
}

body {
  overflow: hidden;
  margin: 0;
  padding: 0;
  color: $color-primary-text;
  background-color: $color-primary-background;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.4;
  font-family: 'Montserrat', sans-serif;
}

div {
  box-sizing: border-box;
}