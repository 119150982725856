@import 'sass/import';

.pushDetails {
  width: 100%;
  margin: 0 16px;

  &__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    margin: 16px 0;
  }

}