.userImage {
  &__image {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    margin-right: 12px;

    &__small {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      margin-right: 12px;
    }
  }
}