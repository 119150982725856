@import 'sass/import';

.ldsRing {
  display: inline-block;
  position: relative;

  &__large {
    width: 80px;
    height: 80px;
    div {
      width: 64px;
      height: 64px;
      margin: 6px;
      border: 6px solid $color-primary;
    }
  }

  &__medium {
    width: 60px;
    height: 60px;
    div {
      width: 48px;
      height: 48px;
      margin: 5px;
      border: 5px solid $color-primary;
    }
  }

  &__small {
    width: 40px;
    height: 40px;
    div {
      width: 32px;
      height: 32px;
      margin: 4px;
      border: 4px solid $color-primary;
    }
  }

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $color-primary transparent transparent transparent;
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
}



@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
