@import 'sass/import';

.splittedLayout {
  height: 100%;
  display: flex;
  flex-direction: row;

  &__left {
    height: 100%;
    width: 50%;
    overflow-y: auto;
    border-right: 1px solid $color-divider;
    @include device('desktop') {
      width: 30%;
    }
    > p { 
      margin: 0 16px;
    }
  }

  &__right {
    overflow-y: auto;
    height: 100%;
    flex-grow: 1;
    width: min-content;
  }
}