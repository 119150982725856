$breakpoints: (
  'tabletLandscape': 1024px,
  'desktop': 1241px,
  'largeDesktop': 1961px,
) !default;

@mixin device($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

@mixin list-item {
  position: relative;
  cursor: pointer;
  margin: 16px;
  padding: 8px 40px 8px 8px;
  border-radius: 8px;
  transition: all 0.25s cubic-bezier(0.165, 0.84, 0.44, 1);
  box-shadow: 0px 1px 5px -3px rgba(0,0,0,0.60);

  &__selected {
    pointer-events: none;
    box-shadow: none;
    background-color: $color-selection-background;
    &:hover {
      box-shadow: none !important;
    }
    &__icon {
      opacity: 0.6;
    }
  }

  &:hover {
    box-shadow: 0px 2px 10px -3px rgba(0,0,0,0.60);
    &__icon {
      opacity: 0.6;
    }
  }

  &__icon {
    position: absolute;
    right: 8px;
    top: 8px;
    width: 24px;
    height: 24px;
    opacity: 0.3;
  }
}

$very-dark-gray: #717171;
$grayish-red: #c4c2c2;
$very-light-black-alpha: #0000000f;
$very-light-gray: #f0f0f0;
$black: #000;
$white: #FFF;
$benelli-red: #ee3124;

$color-primary-text: $black;
$color-primary-dark-text: $white;
$color-secondary-text: $very-dark-gray;
$color-primary: $benelli-red;
$color-primary-background: $white;
$color-secondary-background: $very-light-gray;
$color-selection-background: $very-light-black-alpha;
$color-divider: $grayish-red;