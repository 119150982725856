@import 'sass/import';

.loading {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__logo {
    margin: 24px;
    width: 50%;
    max-width: 300px;
  }
}