@import 'sass/import';

.header {
  background-color: $color-secondary-background;
  padding: 12px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px -12px 11px 10px rgba(0,0,0,0.61);
  
  &__logo {
    max-width: 150px;
    max-height: 28px;
    height: 100%;
  }

  &__logo-link {
    cursor: pointer;
    opacity: 0.4;
    max-width: 100px;
    max-height: 16px;
    transition: all 0.25s cubic-bezier(0.165, 0.84, 0.44, 1);

    &:hover {
      opacity: 1;
    }
  }
}