@import 'sass/import';

.modal {
  position: absolute;
  box-sizing: border-box;
  background-color: #c4c2c270;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;


  &__container {
    width: 100%;
    max-width: 350px;
    height: 100%;
    max-height: 500px;
    padding: 8px;
  }

  &__card {
    overflow-y: auto;
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background-color: $color-primary-background;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0px 2px 10px -3px rgba(0,0,0,0.60);
    align-items: center;
    justify-content: center;
    display: flex;
  }

  &__close {
    position: absolute;
    right: 12px;
    top: 12px;
    cursor: pointer;
  }
}