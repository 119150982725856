@import 'sass/import';

.userProfile {
  &__thumb {
    cursor: pointer;
    box-shadow: none;
    &:hover {
      box-shadow: 0px 2px 10px -3px rgba(0,0,0,0.60);
    }
  }

  &__list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    > * {
      margin-bottom: 8px;
    }
  }

  &__image {
    width: 92px;
    height: 92px;
    border-radius: 50%;
  }
}