@import 'sass/import';

.userInfoDetails {
  width: 100%;
  padding: 32px;

  &__image {
    width: 20vw;
    height: 20vw;
    margin: 0;
  }

  &__horizontal {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 16px;
  }


  &__centeredList {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 16px;
    line-height: 1.6;
  }

  &__light {
    font-weight: 100;
  }

  &__session {
    &__copy {
      margin-right: 8px;
      opacity: 0;
    }

    &:hover {
      .userInfoDetails__session__copy {
        opacity: 1;
      }
    }
  }
}