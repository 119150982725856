@import 'sass/import';

.button {
  cursor: pointer;
  font-size: 14px;
  outline:none;
  font-weight: bold;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: $color-secondary-background;
  padding: 8px 16px;
  border: 1px solid $color-primary;
  color: $color-primary;
  transition: all 0.25s cubic-bezier(0.165, 0.84, 0.44, 1);
  letter-spacing: 1px;
  box-shadow: none;

  &:hover {
    box-shadow: 0px 2px 10px -3px rgba(0,0,0,0.60);
  }

  &__full {
    text-align: center;
    width: 100%;
  }
  
  &__secondary {
    border-color: $color-secondary-text;
    color: $color-secondary-text;
  }

  &__disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  &__flat {
    background-color: transparent;
    border: none;
    padding: 8px 8px;

    &:hover {
      box-shadow: none;
      background-color: $color-selection-background;
      text-shadow: 0px 1px 3px rgba(150, 150, 150, 0.3);
    }
  }
}